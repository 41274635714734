import * as React from 'react';
import { Container, Link, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import DoneIcon from '@mui/icons-material/Done';
import logo from '../assets/css-logo.png';
import { getOrderAirwaybill } from '../ApiService/Service';

const StyledImage = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
}));

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '60vh',
}));

const StyledContentContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    textAlign: 'center',
}));

const ActionContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2), // Space between the link and button
    marginTop: theme.spacing(2),
}));

const iconStyle = {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#39a713',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
};

export default function Success() {
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        if (localStorage.getItem('orderID')) {
            const fetchData = async () => {
                const result = await getOrderAirwaybill(localStorage.getItem('orderID'), process.env.REACT_APP_MARKETPLACE_ID);
                setData(result.data);
            };
            fetchData();
        }
    }, [localStorage.getItem('orderID')])
    return (
        <>
            <Container>
                <StyledImage>
                    <img src={logo} alt='Logo' />
                </StyledImage>
            </Container>
            <StyledContainer>
                <StyledContentContainer>
                    <div style={iconStyle}>
                        <DoneIcon style={{ fontSize: '35px', color: 'white' }} />
                    </div>
                    <Typography variant="h4" style={{ marginTop: 20 }}>
                        Account
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        Created Successfully
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: '5px' }}>
                        We have sent your login details to your email
                        <Typography>
                            Please check your inbox.
                        </Typography>
                    </Typography>

                    {/* Action Buttons: Resend Email and Download Airwaybill */}
                    <ActionContainer>
                        <Link href="#" underline="none">
                            <Typography style={{ color: '#26ACE2', fontWeight: '600' }}>
                                Resend Email
                            </Typography>
                        </Link>
                        {data && data.value && (
                            <Link
                                href={data.value}
                                underline="none"
                                target="_blank"
                            >
                                <Typography style={{ color: '#26ACE2', fontWeight: '600' }}>
                                    Download Airway Bill
                                </Typography>
                            </Link>
                        )}

                    </ActionContainer>
                </StyledContentContainer>
            </StyledContainer>
        </>
    );
}
